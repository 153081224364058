import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
import render from "../assets/images/favicon.png"
import { FaEdit } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { saveAs } from 'file-saver';
import { CiSaveUp1 } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";
import { getblogdetails, blogidDelete, categoryAdd } from "../api/authapi";
import { data } from 'jquery';
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Swal from 'sweetalert2'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RxCross2 } from "react-icons/rx";
import Spinner from 'react-bootstrap/Spinner';
import isEmpty from 'is-empty';

function Blog() {

  const navigate = useNavigate();
  const [blogsdata, setBlogdata] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [isListView, setIsListView] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(3);
  const [totalRecords, setTotalRecords] = useState(0);
  const [show, setShow] = useState(false);
  const [category, setCategory] = useState({
    title: "",
    name: "",
    link: ""
  })
  const [errmsg, setErrmsg] = useState({})
  const [isload, setIsload] = useState(false);

  const handleClose = () => {
    setShow(false)
    setCategory({
      title: "",
      name: "",
      link: ""
    })
    setErrmsg({})
  };
  const handleShow = () => setShow(true);

  const fetchblogs = async () => {
    try {
      const response = await getblogdetails(page, perpage);

      // const response =await axios.get(`https://gorest.co.in/public/v1/users?page=${page}&per_page=${perpage}`)

      if (response.status === true) {
        const pushBlogs = [];

        let Datalist = response.data
        Datalist.forEach(element => {
          const Objvalue = {}
          const data = formatDate(element.CreatAt)

          Objvalue['_id'] = element._id;
          Objvalue['blogtitle'] = element.blogtitle;
          Objvalue['blogcategory'] = element.blogcategory;
          Objvalue['bloglink'] = element.bloglink;
          Objvalue['Status'] = element.Status;
          Objvalue['CreatAt'] = data;

          pushBlogs.push(Objvalue);
        });

        setBlogdata(pushBlogs)
        setTotalRecords(response.totalRecords);
      } else {
        setBlogdata([])
        setTotalRecords(0);
      }

    } catch (error) {
      console.log("fetchblogs Error:", error);
    }
  }

  useEffect(() => {
    fetchblogs();
  }, [page, perpage])

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Format the date to "Month Day, Year"
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }).format(date);

    return formattedDate;
  }


  const handlefilter = (event) => {
    const value = event.target.value || "";
    console.log('value', value)
    setFilterText(value)
  }

  const filteredItems = blogsdata.filter((row) => {
    return Object.values(row).some((val) =>
      val.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  });

  const handleButtonClick = () => {
    setShowPagination(!showPagination);
  };

  const handleViewToggle = () => {
    setIsListView(!isListView);
  };

  const handleFilterChange = (columnId) => {
    setSelectedColumn(columnId);
  };

  const getFilteredColumns = () => {
    if (!selectedColumn) return columnsone;
    return columnsone.filter(col => col.id === selectedColumn);
  };

  const getFilteredTable = () => {
    if (!selectedColumn) return columnsone;
    // Include "S.no" and "Action" columns by default
    return columnsone.filter(col => col.id === 'sno' || col.id === 'action' || col.id === selectedColumn);
  };

  const handleclose = () => {
    if (filterText) {
      setFilterText('');
    }
  }

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerRowsChange = (newPerPage) => {
    setPerpage(newPerPage);
    setPage(1);
  };

  // filesavetype

  const exportToFile = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    saveAs(blob, fileName);
  };

  const convertToJSON = (data) => JSON.stringify(data, null, 2);

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(',');
    const rows = data.map(row => Object.values(row).join(',')).join('\n');
    return `${headers}\n${rows}`;
  };

  const convertToXML = (data) => {
    const xmlRows = data.map(row =>
      `<row>\n${Object.entries(row).map(([key, value]) => `  <${key}>${value}</${key}>`).join('\n')}\n</row>`
    ).join('\n');
    return `<rows>\n${xmlRows}\n</rows>`;
  };

  const convertToTXT = (data) => data.map(row => Object.values(row).join(' ')).join('\n');

  const convertToSQL = (data, tableName) => {
    const headers = Object.keys(data[0]).join(', ');
    const values = data.map(row =>
      `(${Object.values(row).map(value => `'${value}'`).join(', ')})`
    ).join(',\n');
    return `INSERT INTO ${tableName} (${headers}) VALUES\n${values};`;
  };

  const convertToExcel = (data) => {
    // For simplicity, returning CSV format
    return convertToCSV(data);
  };

  const handleDownload = (format) => {
    let data;
    switch (format) {
      case 'json':
        data = convertToJSON(dataone);
        exportToFile(data, 'data.json', 'application/json');
        break;
      case 'csv':
        data = convertToCSV(dataone);
        exportToFile(data, 'data.csv', 'text/csv');
        break;
      case 'xml':
        data = convertToXML(dataone);
        exportToFile(data, 'data.xml', 'application/xml');
        break;
      case 'txt':
        data = convertToTXT(dataone);
        exportToFile(data, 'data.txt', 'text/plain');
        break;
      case 'sql':
        data = convertToSQL(dataone, 'table_name'); // Replace 'table_name' with your actual table name
        exportToFile(data, 'data.sql', 'application/sql');
        break;
      case 'excel':
        data = convertToExcel(dataone);
        exportToFile(data, 'data.csv', 'application/vnd.ms-excel'); // Note: Excel can read CSV files
        break;
      default:
        break;
    }
  };

  // endfilesavetype
  async function HandleEdit(id) {
    navigate(`/editblog/${id}`);
  }

  const columnsone = [
    { name: 'S.no', selector: (row, index) => index + 1, sortable: true, id: 'sno', width: '80px' },
    { name: 'Blog Title', selector: row => row.blogtitle, sortable: true, id: 'blogtitle', width: '200px' },
    { name: 'Blog Category', selector: row => row.blogcategory, sortable: true, id: 'blogcategory', width: '150px' },
    { name: 'Blog Category Link', selector: row => row.bloglink, sortable: true, id: 'bloglink', width: '150px' },
    {
      name: 'Status', selector: (row) => (
        row.Status ? (<div class='py-1 px-2 bg-success rounded-2' style={{ cursor: "pointer" }}>published</div>) : (""))
      , sortable: true, id: 'Status', width: '150px'
    },
    { name: 'Date', selector: row => row.CreatAt, sortable: true, id: 'CreatAt', width: '180px' },
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <a onClick={() => HandleEdit(row._id)} class="text-primary btn" style={{ marginRight: '10px' }}><FaEdit className='me-2' /></a>
          <a onClick={() => HandleDelete(row._id)} class="text-primary btn"><RiDeleteBin6Line /></a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '120px',
      id: 'action',
    },
  ];

  const dataone = [
    {
      id: 1,
      name: '1',
      title: <div className='ms-1'>Exploring the D-ecosystem Galaxy: A Universe of Crypto Innovation</div>,
      link: <div className='ms-1'>Exploring-the-D-ecosystem-Galaxy-A-Universe-of-
        Crypto-Innovation</div>,
      category:
        <div className='ms-1'>
          Announcement
        </div>,
      data:
        <div className='ms-1'>
          March 25, 2024
        </div>,
      action:
        <div className='ms-1'>
          <a href='/editblog'><FaEdit className='me-2' /></a><RiDeleteBin6Line className='fire' />
        </div>,
    },
    {
      id: 2,
      name: '2',
      title: <div className='ms-1'>Exploring the D-ecosystem Galaxy: A Universe of Crypto Innovation</div>,
      link: <div className='ms-1'>Exploring-the-D-ecosystem-Galaxy-A-Universe-of-
        Crypto-Innovation</div>,
      category:
        <div className='ms-1'>
          Announcement
        </div>,
      data:
        <div className='ms-1'>
          March 25, 2024
        </div>,
      action:
        <div className='ms-1'>
          <a href='/editblog'><FaEdit className='me-2' /></a><RiDeleteBin6Line />
        </div>,
    },
    {
      id: 3,
      name: '3',
      title: <div className='ms-1'>Exploring the D-ecosystem Galaxy: A Universe of Crypto Innovation</div>,
      link: <div>Exploring-the-D-ecosystem-Galaxy-A-Universe-of-
        Crypto-Innovation</div>,
      category:
        <div className='ms-1'>
          Announcement
        </div>,
      data:
        <div className='ms-1'>
          March 25, 2024
        </div>,
      action:
        <div className='ms-1'>
          <a href='/editblog'><FaEdit className='me-2' /></a><RiDeleteBin6Line />
        </div>,
    },
    {
      id: 4,
      name: '4',
      title: <div className='ms-1'>Exploring the D-ecosystem Galaxy: A Universe of Crypto Innovation</div>,
      link: <div className='ms-1'>Exploring-the-D-ecosystem-Galaxy-A-Universe-of-
        Crypto-Innovation</div>,
      category:
        <div className='ms-1'>
          Announcement
        </div>,
      data:
        <div className='ms-1'>
          March 25, 2024
        </div>,
      action:
        <div className='ms-1'>
          <a href='/editblog'><FaEdit className='me-2' /></a><RiDeleteBin6Line />
        </div>,
    },
    {
      id: 5,
      name: '5',
      title: <div className='ms-1'>Exploring the D-ecosystem Galaxy: A Universe of Crypto Innovation</div>,
      link: <div className='ms-1'>Exploring-the-D-ecosystem-Galaxy-A-Universe-of-
        Crypto-Innovation</div>,
      category:
        <div className='ms-1'>
          Announcement
        </div>,
      data:
        <div className='ms-1'>
          March 25, 2024
        </div>,
      action:
        <div className='ms-1'>
          <a href='/editblog'><FaEdit className='me-2' /></a><RiDeleteBin6Line />
        </div>,
    },
  ];

  const HandleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!"
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const idval = { blogId: id }
          console.log('idval', idval)

          const response = await blogidDelete(idval);

          if (response.status === true) {
            // toast.success('Success Deleted!');
            await fetchblogs()
            Swal.fire({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success"
            });
          } else {
            toast.success('Somthing in Error!');
          }
        } catch (error) {
          console.log("HandleDelete Error:", error);
        }

      }
    });

  }

  const validateValues = (InputVal) => {
    const error = {}

    if (InputVal.title !== undefined) {
      if (isEmpty(InputVal.title)) {
        error['title'] = "Category title required!"
      }
    }

    if (InputVal.name !== undefined) {
      if (isEmpty(InputVal.name)) {
        error['name'] = "Category name required!"
      }
    }

    if (InputVal.link !== undefined) {
      if (isEmpty(InputVal.link)) {
        error['link'] = "Category link required!"
      }
    }

    return error;
  }

  const onChangeCategory = (event) => {
    try {
      const { name, value } = event.target;
      const updateValue = { ...category, [name]: value }
      setCategory(updateValue)

      const fieldError = validateValues({ [name]: value });
      setErrmsg((prevErrors) => ({
        ...prevErrors,
        ...fieldError,
        [name]: fieldError[name] || "",
      }))
    } catch (error) {
      console.log("onChangeCategory Error:", error)
    }
  }

  const HandleCategorySubmit = async (e) => {
    try {
      e.preventDefault();
      const validatemsg = validateValues(category);
      setErrmsg(validatemsg)

      if (Object.keys(validatemsg).length === 0) {
        setIsload(true);
        const EnData = {
          title: category.title,
          category: category.name,
          categorylink: category.link
        }
        const response = await categoryAdd(EnData)

        if (response.status === true) {
          toast.success("Successfully Submit")
          handleClose();
          setIsload(false)
        } else {
          toast.warn("Data Not Saved!")
          setIsload(false)
        }
      }
    } catch (error) {
      console.log("HandleCategorySubmit Error:", error);
      toast.warn("Data Not Saved!")
      setIsload(false)
    }
  }


  // const [show, setShow] = useState(true); // Modal visibility state

  // const handleClose = () => setShow(false); // Function to close modal


  return (
    <div className='App'>
      <div className='container-fluid'>
        <div className='custom-blog min-vh-100 text-white'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='custom-blog-inside'>
                  <h2>Blogs</h2>

                </div>
                <div className='d-flex justify-content-between align-items-baseline'>
                  <div>
                    <a href='/addblog'><button className='liquidity-btn-1'>ADD Blogs</button></a>
                    <a href='#' className='ms-3'><button className='liquidity-btn-1' variant="primary" onClick={handleShow}>BlogsCategory Add</button></a>
                  </div>

                  <div className='custom-blog-search d-flex align-items-baseline'>
                    <input type="search" id="inputPassword6" class="form-control input-text-2" value={filterText} onChange={handlefilter} aria-describedby="passwordHelpInline" placeholder="search" />
                    {/* <div className='ms-3'>
                      <button className=' mt-4 custom-icon-btn-1' onClick={handleclose}>
                        <GrFormClose />
                      </button>
                    </div>
                    <div className='ms-3'>
                      <button className=' mt-4 custom-icon-btn-1' onClick={handleButtonClick}>
                        {showPagination ? <FaEye /> : <FaEyeSlash />}
                      </button>
                    </div>
                    <button className="mt-4 custom-icon-btn-1 ms-3" onClick={handleViewToggle}>
                      <FaListUl />
                    </button> */}
                    {/* <div className="dropdown ms-3">
                      <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <BsLayoutTextWindowReverse />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#" onClick={() => handleFilterChange(null)}>Show All</a>
                        </li>
                        {columnsone.map((col, index) => (
                          <li key={index}>
                            <a className="dropdown-item" href="#" onClick={() => handleFilterChange(col.id)}>{col.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div> */}
                    {/* <div className="dropdown ms-3">
                      <button className=" dropdown-toggle custom-icon-btn-1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <CiSaveUp1 />
                      </button>
                      <ul className="dropdown-menu">
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('json')}>JSON</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('xml')}>XML</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('csv')}>CSV</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('txt')}>TXT</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('sql')}>SQL</a></li>
                        <li><a className="dropdown-item" href="#" onClick={() => handleDownload('excel')}>Excel</a></li>
                      </ul>
                    </div> */}
                  </div>
                </div>

              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='liquidity-table-1 mt-3'>
                  <div >
                    {/* {isListView ? (
                      <div>
                        {filteredItems.map((row, index) => (
                          <div key={index} style={{ marginBottom: '20px', border: '1px solid #063b81a7', padding: '10px' }}>
                            {getFilteredTable().map((col, colIndex) => (
                              <div key={colIndex} style={{ marginBottom: '10px', display: "flex" }}>
                                <strong>{col.name}:</strong>
                                {col.id === 'sno' ? (index + 1) : col.id === 'action' ? col.cell(row) : row[col.id]}
                              </div>
                            ))}
                          </div>
                        ))}

                      </div>
                    ) : (
                      <DataTable
                        columns={getFilteredColumns()}
                        data={filteredItems}
                        fixedHeader
                        pagination={showPagination}
                        paginationPerPage={2}
                        paginationServer
                        paginationTotalRows={totalRecords}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        theme="solarized"
                        defaultSortAsc={true}
                        highlightOnHover
                        dense
                      />
                    )} */}
                    <DataTable
                      columns={getFilteredColumns()}
                      data={filteredItems}
                      fixedHeader
                      pagination={showPagination}
                      paginationPerPage={perpage}
                      paginationServer
                      paginationTotalRows={totalRecords}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      theme="solarized"
                      defaultSortAsc={true}
                      highlightOnHover
                      dense
                    />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="custom-modal-12"
      >
        <Modal.Header closeButton>
          <Modal.Title>Category Type</Modal.Title>
        </Modal.Header>
        <div className="modal-blog-body">
          <form onSubmit={HandleCategorySubmit}>
            <Modal.Body>
              <div className="mb-3 row">
                <label htmlFor="inputPassword1" className="col-sm-2 col-form-label">
                  Title
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control input-text-1"
                    id="inputPassword1"
                    placeholder='Category title'
                    value={category.title}
                    onChange={onChangeCategory}
                    name='title'
                  />
                  <div className='mt-2' style={{ color: "#b10c0c" }}>
                    {errmsg.title && (<span>{errmsg.title}</span>)}

                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="inputPassword2" className="col-sm-2 col-form-label">
                  Name
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control input-text-1"
                    id="inputPassword2"
                    placeholder='Category name'
                    value={category.name}
                    onChange={onChangeCategory}
                    name='name'
                  />
                  <div className='mt-2' style={{ color: "#b10c0c" }}>
                    {errmsg.name && (<span>{errmsg.name}</span>)}

                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
                  link
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control input-text-1"
                    id="inputPassword3"
                    placeholder='Category link'
                    value={category.link}
                    onChange={onChangeCategory}
                    name='link'
                  />
                  <div className='mt-2' style={{ color: "#b10c0c" }}>
                    {errmsg.link && (<span>{errmsg.link}</span>)}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              {isload
                ? (
                  <>
                    <Button variant="primary" className="liquidity-btn-1 text-center" type='submit' disabled={isload}>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </Button>
                  </>
                )
                : (<Button variant="primary" className="liquidity-btn-1 text-center" type='submit'>
                  Submit
                </Button>)}
            </Modal.Footer>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default Blog