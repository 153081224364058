
import React, { useEffect } from 'react';
import { useRef, useState } from 'react';
import DataTable from 'react-data-table-component';
import { CiCirclePlus } from "react-icons/ci";
import { getAllPool, setFroms, updateFormStatus, getFroms, allForms, updatePoolStatus } from "../api/swapControlapi";
import { IoIosSearch } from "react-icons/io";
import { BsPlusCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import ethereum from "../assets/images/ethereum.png";
import polygon from "../assets/images/polygon.png";
import bnb from "../assets/images/bnb.png";
import dcx from '../assets/images/dchainimages.png';
import config from "../config/config";
import { ToastContainer, toast } from "react-toastify";
import stakeContractAbi from "../Abi/Stake.json";
import Web3 from 'web3';
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
    useNetwork,
    useSwitchNetwork,
} from "wagmi";
import metamask from "../assets/images/metamask.png";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Addpools(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => {setShow(false); setApy("") }
    const handleShow = () => setShow(true);

    const { data } = props;
    const { connect, connectors, isLoading, pendingConnector } = useConnect();
    const { chain } = useNetwork();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { chains, pendingChainId, error, switchNetwork } = useSwitchNetwork();
    const { address, isConnected } = useAccount();
    const { data: ensName } = useEnsName({ address });
    const { data: ensAvatar } = useEnsAvatar({ name: ensName });
    const trigerWalletRef = useRef(null);
    const { disconnect } = useDisconnect();
    const [searchInPositionTable, setSearchInPositionTable] = useState('');
    const [searchInPositionTableForms, setSearchInPositionTableForms] = useState('');
    const [chainListTableData, setChainListTableData] = useState([]);
    const [chainIdList, setChainIdList] = useState([5272]);
    const [usercurrentPage, setUserCurrentPage] = useState(1);
    const [allPoolTable, setAllPoolTable] = useState([]);
    const [tableStatus, setTableStatus] = useState(0);
    const [allFormsData, setAllFormsData] = useState([])
    const [formsData, setFromsData] = useState({
        name_A_token: '',
        symbol_A_token: '',
        name_B_token: '',
        symbol_B_token: '',
        token_A_address: '',
        token_B_adderss: '',
        pair_Address: '',
        logoURI_A: '',
        logoURI_B: '',
        chain_Id: '',
    });
    const [fromsTable, setFromTable] = useState([]);
    const [apy, setApy] = useState('');

    const handleConnect = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            try {

                // const web3 = new Web3(window.ethereum);
                // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                // const account = accounts[0];
                // if (!account) {
                //   throw new Error('No MetaMask account found');
                // }
                // const message = "Please sign this message to verify your identity.";
                // const signature = await web3.eth.personal.sign(message, account, '');
                connect({ connector: connectors[0] });
                // console.log('Signature:', signature);
            } catch (error) {
                console.error('Error connecting to MetaMask:', error);
            }
        } else {
            window.open('https://metamask.io/download.html', '_blank');
        }
    };

    const getAllpools = async (data) => {
        try {
            let networksDatas;
            if (data.length > 0) {
                networksDatas = data;
            } else {
                networksDatas = ['Polygon', 'D Chain Mainnet', 'BNB Smart Chain', 'Ethereum']
            }
            const res = await getAllPool(networksDatas, searchInPositionTable, usercurrentPage);
            console.log('res', res)
            if (res.status == true) {
                setAllPoolTable(res.data)
            } else {
                setAllPoolTable([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getAllForms = async () => {
        try {
            const res = await allForms();
            if (res.status === true) {
                setAllFormsData(res.data);
            } else {
                setAllFormsData([]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFromsTable = async (chain, searchData) => {
        try {
            let arr;
            if (chain.length > 0) {
                arr = chain;
            } else {
                arr = ['Polygon', 'D Chain Mainnet', 'BNB Smart Chain', 'Ethereum']
            }
            const data = await getFroms(arr, searchData, usercurrentPage);
            if (data.status == true) {
                setFromTable(data.data);
            } else {
                setFromTable([]);
            }
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => { getAllForms() }, [])
    useEffect(() => {
        if (Number(tableStatus) === 0) {
            getAllpools(chainListTableData);
        } else {
            getFromsTable(chainListTableData, searchInPositionTableForms);
        }
    }, [tableStatus, usercurrentPage, searchInPositionTable, searchInPositionTableForms, chainListTableData])


    const showCheck = async (data) => {
        try {
            let updatedCheckStatusList = [...chainListTableData];
            let updatedChainIdList = [...chainIdList];

            if (data === 1) {
                if (chainListTableData.includes('Ethereum') && chainIdList.includes(1)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'Ethereum');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 1);

                } else {

                    updatedCheckStatusList.push('Ethereum');
                    updatedChainIdList.push(1);
                }
            } else if (data === 2) {
                if (chainListTableData.includes('BNB Smart Chain') && chainIdList.includes(56)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'BNB Smart Chain');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 56);
                } else {

                    updatedCheckStatusList.push('BNB Smart Chain');
                    updatedChainIdList.push(56);
                }
            } else if (data === 3) {
                if (chainListTableData.includes('Polygon') && chainIdList.includes(137)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'Polygon');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 137);
                } else {

                    updatedCheckStatusList.push('Polygon');
                    updatedChainIdList.push(137)
                }
            } else {
                if (chainListTableData.includes('D Chain Mainnet') && chainIdList.includes(5272)) {
                    updatedCheckStatusList = updatedCheckStatusList.filter(item => item !== 'D Chain Mainnet');
                    updatedChainIdList = updatedChainIdList.filter(item => item !== 5272);
                } else {

                    updatedCheckStatusList.push('D Chain Mainnet');
                    updatedChainIdList.push(5272);
                }
            }
            setChainListTableData(updatedCheckStatusList);
            setChainIdList(updatedChainIdList);
        } catch (error) {
            console.log(error);
        }
    }

    const addPoolsSetUp = (data) => {
        try {
            if (data) {
                setFromsData({
                    name_A_token: data.name_A_token,
                    symbol_A_token: data.symbol_A_token,
                    name_B_token: data.name_B_token,
                    symbol_B_token: data.symbol_B_token,
                    token_A_address: data.token_A_address,
                    token_B_adderss: data.token_B_adderss,
                    pair_Address: data.pair_Address,
                    logoURI_A: data.logoURI_A,
                    logoURI_B: data.logoURI_B,
                    chain_Id: data.chain_Id,
                })
                handleShow();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleChangeApy = (e) => {
        try {
            const value = e;
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value) && !/^0{2,}/.test(value)) {
                setApy(value);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const columnsone = [
        {
            name: 'S.no',
            selector: row => row.number,
            sortable: true,
            width: "100px",
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Chain',
            selector: row => row.chain,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Pool',
            selector: row => row.pool,
            sortable: true,
        },
        // {
        //     name: 'TOKEN A',
        //     selector: row => row.tokena,
        //     sortable: true,
        // },
        // {
        //     name: 'TOKEN B',
        //     selector: row => row.tokenb,
        //     sortable: true,
        // },
        {
            name: 'Status',
            selector: row => row.Status,
            sortable: true,
            width: "150px"
        },

        {
            name: 'Update',
            selector: row => row.AddPool,
            sortable: true,
            width: "150px"
        }
    ];

    const dataone = allPoolTable && allPoolTable.map((data, index) => {
        return {
            id: index + 1,
            number: <div>{index + 1}</div>,
            name: `${data.name_A_token}/${data.name_B_token}`,
            chain: data.chain_Id == config.Rpc.Dchain.chainName ? "Dchain" : data.chain_Id == config.Rpc.poly.chainName ? "Polygon" :
                data.chain_Id == config.Rpc.Bnb.chainName ? "Bsc" : data.chain_Id == config.Rpc.Eth.chainName ? "Ethereum" : "",
            pool: <div>{data.pair_Address}</div>,
            tokena: <div>{data.token_A_address}</div>,
            tokenb: <div>{data.token_B_adderss}</div>,
            Status: <div><button className={`liquidity-btn-1 ${data.viewMode === true ? "active-btn" : "inactive-btn"}`} onClick={() => { viewhideMode(data) }}> {data.viewMode === true ? 'Active' : "In Active"} </button></div>,
            AddPool: <div><button className='liquidity-btn-1' onClick={() => { addPoolsSetUp(data) }}> Update </button></div>,
        };
    });

    const handleSubmit = async (data) => {
        try {
            if (data) {
                if (Number(apy) === 0) {
                    toast("(APY) is required!");
                    return;
                }
                const checkIncludeAllready = allFormsData.filter((d) => d.pair_Address === data.pair_Address);
                if (checkIncludeAllready.length == 0) {
                    let gas;
                    let gesfee;
                    let contractAddress;
                    const rpc = data.chain_Id == config.Rpc.Eth.chainName ? config.Rpc.Eth.rpc :
                        data.chain_Id == config.Rpc.Bnb.chainName ? config.Rpc.Bnb.rpc :
                            data.chain_Id == config.Rpc.poly.chainName ? config.Rpc.poly.rpc :
                                data.chain_Id == config.Rpc.Dchain.chainName ? config.Rpc.Dchain.rpc : ''
                    let provi1der = window.ethereum ? window.ethereum : new Web3.providers.HttpProvider(rpc);
                    let web3 = new Web3(provi1der);
                    // eslint-disable-next-line default-case
                    switch (data.chain_Id) {
                        case config.Rpc.Eth.chainName:
                            gas = 2000000;
                            gesfee = await web3.eth.getGasPrice();
                            contractAddress = config.Lp_stake.Flexible.Eth.Stake;
                            break;
                        case config.Rpc.Bnb.chainName:
                            gas = 2000000;
                            gesfee = await web3.eth.getGasPrice();
                            contractAddress = config.Lp_stake.Flexible.Bnb.Stake;
                            break;
                        case config.Rpc.poly.chainName:
                            gas = 2000000;
                            gesfee = await web3.eth.getGasPrice();
                            contractAddress = config.Lp_stake.Flexible.poly.Stake;
                            break;
                        case config.Rpc.Dchain.chainName:
                            gas = 2000000;
                            gesfee = web3.utils.toWei("4", "Gwei");
                            contractAddress = config.Lp_stake.Flexible.Dchain.Stake;
                            break;
                    }
                    const apyInWei = web3.utils.toWei(apy.toString(), 'ether'); 

                    const contract = new web3.eth.Contract(stakeContractAbi, contractAddress)
                    const owneraddress = await contract.methods.owneraddress().call();

                    if (owneraddress !== address) {
                        return  toast.warn(`Please connect with address ${owneraddress.substring(0, 5)}...${owneraddress.substring(owneraddress.length - 4)}`)
                    }

                    const responce = await contract.methods.add(Number(apyInWei), 1, data.pair_Address, true).send({ from: address, gas: gas, gasPrice: gesfee });
                    const pid = responce.events.AddPool.returnValues.pid;
                    let payload = {
                        name_A_token: data.name_A_token,
                        symbol_A_token: data.symbol_A_token,
                        name_B_token: data.name_B_token,
                        symbol_B_token: data.symbol_B_token,
                        token_A_address: data.token_A_address,
                        token_B_adderss: data.token_B_adderss,
                        pair_Address: data.lpToken,
                        logoURI_A: data.logoURI_A,
                        logoURI_B: data.logoURI_B,
                        chain_Id: data.chain_Id,
                        pid: Number(pid)
                    }
                    const res = await setFroms(payload);
                    if (res.status == true) {
                        toast("Add success");
                        getFromsTable(chainListTableData, searchInPositionTableForms);
                    } else {
                        toast("Add failed");
                    }
                } else {
                    toast("Pair is allready here");
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleStatusChange = async (data) => {
        try {
            if (data) {
                console.log(data)
                let t1 = data.symbol_A_token
                let msgpool = t1.concat("/", data.symbol_B_token);
                const payload = {
                    _id: data._id,
                    pair_Address: data.pair_Address,
                    status: data.status === true ? false : true
                }
                const res = await updateFormStatus(payload);
                console.log('res', res)
                let toastResult = res.data ? "Active" : "In Active";
                if (res.status === true) {
                    toast(`${msgpool} Farms  ${toastResult} Successfully!`);
                    getFromsTable(chainListTableData, searchInPositionTableForms);
                } else {
                    toast(`Farms Status Failed`);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    const columnstwo = [
        {
            name: 's.no',
            selector: row => row.number,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Chain',
            selector: row => row.chain,
            sortable: true,
        },
        {
            name: 'POOL',
            selector: row => row.pool,
            sortable: true,
        },
        {
            name: 'TOKEN A',
            selector: row => row.tokena,
            sortable: true,
            width: "300px",
        },
        {
            name: 'TOKEN B',
            selector: row => row.tokenb,
            sortable: true,
            width: "300px",
        },
        {
            name: 'Active',
            selector: row => row.button1,
            sortable: true,
        },
        // {
        //     name: '',
        //     selector: row => row.button2,
        //     sortable: true,
        // }
    ];

    const datatwo = fromsTable && fromsTable.map((data, index) => {

        return {
            id: index + 1,
            number: <div>{index + 1}</div>,
            name: `${data.symbol_A_token}/${data.symbol_B_token}`,
            chain: data.chain_Id == config.Rpc.Dchain.chainName ? "Dchain" : data.chain_Id == config.Rpc.poly.chainName ? "Polygon" :
                data.chain_Id == config.Rpc.Bnb.chainName ? "Bsc" : data.chain_Id == config.Rpc.Eth.chainName ? "Ethereum" : "",
            pool: <div>{data.pair_Address}</div>,
            tokena: <div>{data.token_A_address}  </div>,
            tokenb: <div> {data.token_B_adderss}</div>,
            button1: <div><button className={`liquidity-btn-1 ${data.status === true ? "active-btn" : "inactive-btn"}`} onClick={() => { handleStatusChange(data) }}>{data.status === true ? 'Active' : "In Active"}</button> </div>,
            // button2: <div> <button className='liquidity-btn-1'>Remove</button></div>
        }
    })

    const viewhideMode = async (data) => {
        try {
            if (data) {
                console.log(data)
                let t1 = data.symbol_A_token
                let msgpool = t1.concat("/", data.symbol_B_token);
                const payload = {
                    pairAddress: data.pair_Address,
                    status: data.viewMode === true ? false : true
                }
                const res = await updatePoolStatus(payload);
                console.log('res', res)
                let toastResult = res.data ? "Active" : "In Active";
                if (res.status === true) {
                    toast(`${msgpool} Pools ${toastResult} Successfully!`);
                    getAllpools(chainListTableData);
                } else {
                    toast('Pools Status Failed');
                }
            }
        } catch (error) {
            console.log("viewhideMode Error:", error);
        }
    }

    return (
        <div className='App'>
            <div className='container-fluid add-pools-btn'>
                <div className='custom-add-1 min-vh-100 text-white'>
                    <div className='continer'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className="d-flex justify-content-between align-items-center">
                                    <h2>Addpools</h2>
                                    <div className="d-flex">
                                        <ul class="navbar-nav ms-auto-lg mb-2 me-3 mb-lg-0 gap-1 nav-dropdown-second justify-content-center align-items-center">
                                            <li class="nav-item dropdown home-hide">
                                                <div class="dropdown">
                                                    <button
                                                        class="btn gap-2 d-block nav-link dropbtn"
                                                        type="submit"
                                                        href="#"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src={
                                                                chain?.name === "Ethereum"
                                                                    ? ethereum
                                                                    : chain?.name === "BNB Smart Chain"
                                                                        ? bnb
                                                                        : chain?.name === "Polygon"
                                                                            ? polygon
                                                                            : dcx
                                                            }
                                                            className="ethe-img"
                                                            alt="etherum"
                                                        />
                                                        {chain?.name == "Ethereum" ||
                                                            chain?.name == "BNB Smart Chain" ||
                                                            chain?.name == "Polygon" ? (
                                                            <span className="mobile-none"> {chain?.name} </span>
                                                        ) : (
                                                            <span className="mobile-none"> D Chain Mainnet </span>
                                                        )}
                                                    </button>
                                                    <ul class="dropdown-menu" style={{ cursor: "pointer" }}>
                                                        {/* <li>
                      <div className="dropdown-tx-2 d-flex">
                        <input
                          class="form-control me-2 ps-2"
                          type="search"
                          placeholder="Search Network"
                          aria-label="Search"
                        />
                      </div>
                    </li> */}
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => switchNetwork?.(chains[0].id)}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={ethereum}
                                                                        className="ethe-img"
                                                                        alt="etherum"
                                                                    />
                                                                    <span className="dropdown-tx-2 ps-lg-2">
                                                                        {" "}
                                                                        Ethereum
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => switchNetwork?.(chains[1].id)}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={polygon}
                                                                        className="ethe-img"
                                                                        alt="polygon"
                                                                    />
                                                                    <span className="dropdown-tx-2 ps-lg-2">
                                                                        {" "}
                                                                        Polygon
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => switchNetwork?.(chains[2].id)}
                                                            >
                                                                <div>
                                                                    <img src={bnb} className="ethe-img" alt="bnb" />
                                                                    <span className="dropdown-tx-2 ps-lg-2"> BNB</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => switchNetwork?.(chains[3].id)}
                                                            >
                                                                <div>
                                                                    <img src={dcx} className="ethe-img" alt="dcx" />
                                                                    <span className="dropdown-tx-2 ps-lg-2"> DCX</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="d-flex">
                                            {isConnected ? (
                                                <button
                                                    class="btn nav-link dropbtn me-3"
                                                    type="submit"
                                                    href="#"
                                                    role="button"
                                                    aria-expanded="false"
                                                >
                                                    {address.substring(0, 5)}...
                                                    {address.substring(address.length - 4)}
                                                </button>
                                            ) : (
                                                <button
                                                    ref={trigerWalletRef}
                                                    class="btn nav-link liquidity-btn-1 connect-wallet"
                                                    type="submit"
                                                    href="#"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Connect Wallet
                                                </button>
                                            )}
                                            <ul
                                                className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                                                class="dropdown-menu"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item1"
                                                        // href="#"
                                                        onClick={() => {
                                                            handleConnect()
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <img
                                                                src={metamask}
                                                                className="ethe-img"
                                                                alt="metamask"
                                                            />
                                                            <span className="dropdown-tx-2 ps-lg-2">
                                                                MetaMask
                                                            </span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            {isConnected === true ? (
                                                <>
                                                    <button
                                                        class="liquidity-btn-2 me-2"
                                                        type="submit"
                                                        href="#"
                                                        role="button"
                                                        aria-expanded="false"
                                                        onClick={disconnect}
                                                    >
                                                        Disconnect
                                                    </button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>

                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-12 mt-2'>
                                {/* <div className='row'>
                                    <div className='col-lg-8'>
                                        <div className='custom-add-1-inside'>
                                            <form>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Pair</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={formsData.symbol_A_token == '' ? '' : `${formsData.symbol_A_token}/${formsData.symbol_B_token}`} placeholder='Pair' />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Apy</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" id="inputPassword" value={apy} placeholder='Apy' onChange={(e) => {
                                                            handleChangeApy(e.target.value);
                                                        }} />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">PairAddress</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" value={formsData.pair_Address} id="inputPassword" placeholder='PairAddress' />
                                                    </div>
                                                </div>
                                                <div class="mb-3 row">
                                                    <label for="inputPassword" class="col-sm-2 col-form-label">Chain</label>
                                                    <div class="col-sm-10">
                                                        <input type="text" class="form-control input-text-1" value={formsData.chain_Id == config.Rpc.Dchain.chainName ? "Dchain" : formsData.chain_Id == config.Rpc.poly.chainName ? "Polygon" :
                                                            formsData.chain_Id == config.Rpc.Bnb.chainName ? "Bsc" : formsData.chain_Id == config.Rpc.Eth.chainName ? "Ethereum" : ""} id="inputPassword" placeholder='Chain' />
                                                    </div>
                                                </div>
                                            </form>
                                            <div className='text-center'>
                                                {isConnected == true ? chain?.name === formsData.chain_Id ? <button className='liquidity-btn-1' onClick={() => { handleSubmit(formsData) }} >Submit</button> :
                                                    <button className='liquidity-btn-1 opacity-50'  >Submit</button> :
                                                    <button className='liquidity-btn-1 opacity-50'  >Submit</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                                <div className='row mt-5'>
                                    <div className='col-lg-12'>


                                        <ul class="nav nav-pills mb-4 mt-2" id="pills-tab" role="tablist">
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link active fees-button" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" onClick={() => { setTableStatus(0) }} aria-selected="true">All pools</button>
                                            </li>
                                            <li class="nav-item" role="presentation">
                                                <button class="nav-link fees-button" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" onClick={() => { setTableStatus(1) }} aria-selected="false">Farms</button>
                                            </li>
                                            <li>
                                                <div class="dropdown custom-coin-1-img">
                                                    <button type="button" class=" px-3 rounded-4 navtab-button dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                        <BsPlusCircle className='me-2 mb-1' />Networks</button>
                                                    <ul class="dropdown-menu" style={{ cursor: "pointer" }} >
                                                        {/* 'Ethereum''BNB Smart Chain''Polygon''D Chain Mainnet' */}
                                                        <li onClick={() => { showCheck(1) }}>
                                                            <a class="dropdown-item" onClick={() => { showCheck(1) }} >
                                                                {chainListTableData.includes('Ethereum') && chainIdList.includes(1) ?
                                                                    <FaCheck className='check-list' /> :
                                                                    <FaCheck className='check-list1' />
                                                                }
                                                                <img src={ethereum} className='dropdown-icon me-2' alt='ethereum'></img>Ethereum
                                                            </a>
                                                        </li>
                                                        <li><a class="dropdown-item" onClick={() => { showCheck(2) }} >
                                                            {chainListTableData.includes('BNB Smart Chain') && chainIdList.includes(56) ?
                                                                <FaCheck className='check-list' /> :
                                                                <FaCheck className='check-list1' />
                                                            }
                                                            <img src={bnb} className='dropdown-icon me-1' alt='bnb'></img>BNB Smart Chain
                                                        </a></li>
                                                        <li><a class="dropdown-item" onClick={() => { showCheck(3) }}>
                                                            {chainListTableData.includes('Polygon') && chainIdList.includes(137) ?
                                                                <FaCheck className='check-list' /> :
                                                                <FaCheck className='check-list1' />
                                                            }

                                                            <img src={polygon} className='dropdown-icon me-2' alt='polygon'></img>Polygon
                                                        </a></li>
                                                        <li><a class="dropdown-item" onClick={() => { showCheck(4) }}>
                                                            {chainListTableData.includes('D Chain Mainnet') && chainIdList.includes(5272) ?
                                                                <FaCheck className='check-list' /> :
                                                                <FaCheck className='check-list1' />
                                                            }

                                                            <img src={dcx} className='dropdown-icon me-2' alt='arbitrumlogo'></img>Dcx
                                                        </a></li>

                                                    </ul>
                                                </div>
                                            </li>



                                        </ul>

                                        <div className='row'>
                                            <div className='col-lg-8'>
                                                <div class="d-flex align-items-center mb-3 position-relative">
                                                    <span class="position-absolute start-0 ps-2" id="basic-addon1" style={{ pointerEvents: 'none', top: '50%', transform: 'translateY(-50%)' }}><IoIosSearch className='input-search-icon' /></span>
                                                    <input type="search" class="form-control ps-4" placeholder="Search" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => { tableStatus == 0 ? setSearchInPositionTable(e.target.value) : setSearchInPositionTableForms(e.target.value) }} />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tab-content" id="pills-tabContent">
                                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                                <div className='liquidity-table-1'>
                                                    <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense />
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                                <div className='liquidity-table-1'>
                                                    <DataTable columns={columnstwo} data={datatwo} theme="solarized" defaultSortAsc={true}
                                                        pagination
                                                        highlightOnHover
                                                        dense />
                                                </div>
                                            </div>

                                        </div>


                                        <Modal
                                            show={show}
                                            onHide={handleClose}
                                            backdrop="static"
                                            keyboard={false}
                                            className="custom-modal-12"
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title>Update</Modal.Title>
                                            </Modal.Header>


                                            <div className="modal-blog-body">
                                                <form>
                                                    <Modal.Body>
                                                        <form>
                                                            <div class="mb-3">
                                                                <label for="exampleInputEmail1" class="form-label">Name A</label>
                                                                <input type="email" value={formsData.name_A_token} class="form-control  input-text-1" id="exampleInputEmail1" aria-describedby="emailHelp" ></input>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputEmail1" class="form-label">Name B</label>
                                                                <input type="email" value={formsData.name_B_token} class="form-control  input-text-1" id="exampleInputEmail1" aria-describedby="emailHelp" ></input>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputPassword1" class="form-label">Chain</label>
                                                                <input type="text" class="form-control  input-text-1" id="exampleInputPassword1" value={formsData.chain_Id} />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputPassword1" class="form-label">Pool</label>
                                                                <input type="text" class="form-control  input-text-1" id="exampleInputPassword1" value={formsData.pair_Address} />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputPassword1" class="form-label">Token A</label>
                                                                <input type="text" class="form-control  input-text-1" id="exampleInputPassword1" value={formsData.token_A_address} />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputPassword1" class="form-label">Token B</label>
                                                                <input type="text" class="form-control  input-text-1" id="exampleInputPassword1" value={formsData.token_B_adderss} />
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="exampleInputPassword1" class="form-label">Apy</label>

                                                                <input type="text" class="form-control input-text-1" id="inputPassword" value={apy} placeholder='Apy' onChange={(e) => {
                                                                    handleChangeApy(e.target.value);
                                                                }} />

                                                            </div>

                                                        </form>
                                                    </Modal.Body>
                                                </form>
                                            </div>

                                            <Modal.Footer className='justify-content-center'>
                                                {isConnected == true ? chain?.name === formsData.chain_Id ? <button className='liquidity-btn-1' onClick={() => { handleSubmit(formsData) }} >Submit</button> :
                                                    <button className='liquidity-btn-1 opacity-50'  >Update</button> :
                                                    <button className='liquidity-btn-1 opacity-50'  >Update</button>
                                                }
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Addpools