import React, { useState, useRef, useEffect } from 'react';
import Dashboard from './Dashboard'
import { GrCloudComputer } from "react-icons/gr";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useFormik } from 'formik';
import { blogsEdit, findblogdetails } from '../api/authapi'
import { useParams, useNavigate } from 'react-router-dom';
import { data } from 'jquery';
import configration from '../config/config';
import { toast } from 'react-toastify';
import { editErrSchema } from './utils/helper';
import { MdOutlineArrowCircleLeft } from "react-icons/md";


function Editblog() {

    const { id } = useParams();
    const navigate = useNavigate();
    const quillRef = useRef(null);
    const [blogData, setBlogData] = useState({
        blogtitle: '',
        bloglink: '',
        blogcategory: '',
        metatitle: '',
        metadescription: '',
        metakey: '',
        blogcontent: '',
        blogimage: null
    });
    const [previewImage, setPreviewImage] = useState(null);

    const formik = useFormik({
        initialValues: {
            blogtitle: '',
            bloglink: '',
            blogcategory: '',
            metatitle: '',
            metadescription: '',
            metakey: '',
            blogcontent: '',
            blogimage: null
        },
        validationSchema: editErrSchema,
        enableReinitialize: true,
        onSubmit: async (values, actions) => {
            console.log('Form submitted with values:', values);

            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key === "blogimage") {
                    if (values[key] instanceof File) {
                        formData.append(key, values[key]);
                    }
                    else if (typeof values[key] === "object" && values[key] !== null) {
                        formData.append(key, JSON.stringify(values[key]));
                    }
                } else {
                    formData.append(key, values[key]);
                }
            });

            const formDataObj = {};

            formData.forEach((value, key) => {
                if (key === "blogimage") {
                    formDataObj[key] = values[key] instanceof File ? value : JSON.parse(value);
                } else {
                    formDataObj[key] = value;
                }
            });

            console.log('Converted FormData Object:', formDataObj);
            const response = await blogsEdit(formData);
            console.log("reponse---", response);
            if (response.status === true) {
                toast.success("Blog Updated Successfully")
                actions.resetForm();
                setTimeout(() => {
                    navigate('/dashboard/profile')
                }, 1000);
            } else {
                toast.warn("error on blog");
            }
        },
    });

    const handleChange = (content, delta, source, editor) => {
        formik.setFieldValue('blogcontent', editor.getHTML());
    };

    const handleFileChange = (event) => {
        const file = event.currentTarget.files[0];
        formik.setFieldValue('blogimage', file);

        // Set preview image for the selected file
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };
    const modules = {
        toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean'],

        ],
    };

    const fetchblogbyid = async () => {
        try {
            const response = await findblogdetails(id);
            console.log("reponse", response);
            const data = response.data
            if (response.status === true) {
                setBlogData(data);
                formik.setValues(data);
                setPreviewImage(`${configration.BACKEND_URL}src/images/${data.blogimage.data}`)
            } else {

            }

        } catch (error) {
            console.log("fetchblogbyid Error:", error);
        }
    }

    useEffect(() => {
        fetchblogbyid();
    }, [id])


    return (
        <div className='App'>
            <div className='container-fluid'>
                <div className='custom-addblog min-vh-100 text-white'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='row'>
                                <div className='col-lg-2'>
                                    <Dashboard />
                                </div>
                                <div className='col-md-12 ms-sm-auto col-lg-10 px-md-4  m-0 p-0'>
                                    <div className='custom-inside-addblog'>
                                        <h2><a className='me-3' href='/dashboard/profile'><MdOutlineArrowCircleLeft /></a>Edit Blogs</h2>
                                        <div className='row justify-content-center align-items-center text-center'>
                                            <div className='col-lg-10 '>
                                                <div className='custom-addblogs-1'>
                                                    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                                                        <div class="row mb-3">
                                                            <label for="blogtitle" class="col-sm-3 col-form-label text-end">Blog Title</label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    class="form-control input-text-2"
                                                                    id="blogtitle"
                                                                    placeholder='title'
                                                                    name='blogtitle'
                                                                    value={formik.values.blogtitle}
                                                                    onChange={formik.handleChange} />
                                                                <div className='text-start  mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.blogtitle && formik.errors.blogtitle ? (<span style={{ fontSize: "12px" }}>{formik.errors.blogtitle}</span>) : null}

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row mb-3">
                                                            <label for="bloglink" class="col-sm-3 col-form-label text-end">Blog Link</label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    class="form-control input-text-2"
                                                                    id="bloglink"
                                                                    placeholder='link'
                                                                    name='bloglink'
                                                                    value={formik.values.bloglink}
                                                                    onChange={formik.handleChange} />
                                                                <div className='text-start  mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.bloglink && formik.errors.bloglink ? (<span style={{ fontSize: "12px" }}>{formik.errors.bloglink}</span>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mb-3 row">
                                                            <label class="col-form-label col-sm-3 text-end" for="inputEmail3">Blog Category</label>
                                                            <div className='col-sm-9'>
                                                                <select
                                                                    class="form-select input-text-2"
                                                                    id="inputGroupSelect01"
                                                                    placeholder="jjjjj"
                                                                    value={formik.values.blogcategory}
                                                                    onChange={formik.handleChange}>
                                                                    {formik.values.blogcategory ? (
                                                                        <option value={formik.values.blogcategory}>
                                                                            {formik.values.blogcategory}
                                                                        </option>
                                                                    ) : (
                                                                        <option value="" disabled>Choose...</option> // Fallback if no category is available
                                                                    )}
                                                                </select>
                                                                <div className='text-start  mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.blogoptions && formik.errors.blogoptions ? (<span style={{ fontSize: "12px" }}>{formik.errors.blogoptions}</span>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <label for="metatitle" class="col-sm-3 col-form-label text-end">Blog Meta Title</label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    class="form-control input-text-2"
                                                                    id="metatitle"
                                                                    name="metatitle"
                                                                    placeholder='Blog Meta Link'
                                                                    value={formik.values.metatitle}
                                                                    onChange={formik.handleChange} />
                                                                <div className='text-start  mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.metatitle && formik.errors.metatitle ? (<span style={{ fontSize: "12px" }}>{formik.errors.metatitle}</span>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <label for="metadescription" class="col-sm-3 col-form-label text-end">Blog Meta description</label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    class="form-control input-text-2"
                                                                    id="metadescription"
                                                                    placeholder='Blog Meta description'
                                                                    name='metadescription'
                                                                    value={formik.values.metadescription}
                                                                    onChange={formik.handleChange}
                                                                />
                                                                <div className='text-start  mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.metadescription && formik.errors.metadescription ? (<span style={{ fontSize: "12px" }}>{formik.errors.metadescription}</span>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <label for="metakey" class="col-sm-3 col-form-label text-end">Blog Meta KeyWords</label>
                                                            <div class="col-sm-9">
                                                                <input
                                                                    type="text"
                                                                    class="form-control input-text-2"
                                                                    id="metakey"
                                                                    name='metakey'
                                                                    placeholder='Blog Meta KeyWords'
                                                                    value={formik.values.metakey}
                                                                    onChange={formik.handleChange} />
                                                                <div className='text-start mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.metakey && formik.errors.metakey ? (<span style={{ fontSize: "12px" }}>{formik.errors.metakey}</span>) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div class="row mb-3">
                                                            <label for="inputEmail3" class="col-sm-3 col-form-label text-end">Blog Schema</label>
                                                            <div class="col-sm-9">
                                                                <textarea class="form-control input-text-2" id="exampleFormControlTextarea1" rows="3"></textarea>
                                                            </div>
                                                        </div> */}
                                                        <div class="row mb-3">
                                                            <label for="inputEmail3" class="col-sm-3 col-form-label text-end">Blog Image</label>
                                                            <div class="input-group mb-3 col-sm-9 " style={{ width: '75%' }}>
                                                                <input
                                                                    type="file"
                                                                    class="form-control input-text-2"
                                                                    placeholder="Recipient's username"
                                                                    aria-label="Recipient's username"
                                                                    aria-describedby="button-addon2"
                                                                    onChange={handleFileChange}
                                                                    name='blogimage'
                                                                    accept='image/*'
                                                                />
                                                                <div className='text-start mt-2' style={{ color: "#b10c0c" }}>
                                                                    {formik.touched.blogimage && formik.errors.blogimage ? (<span style={{ fontSize: "12px" }}>{formik.errors.blogimage}</span>) : null}
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="row mb-3 text-start">
                                                            <label for="inputEmail3" class="col-sm-3 col-form-label text-end">Prview Image</label>
                                                            <div class="col-sm-9 custom-edit-1">
                                                                {previewImage && (
                                                                    <div className="mb-3">
                                                                        <img src={`${previewImage}`} alt="Blog" style={{ width: '100px', height: '100px', marginBottom: '10px' }} />
                                                                    </div>
                                                                )}
                                                                <div className='col-lg-12 '>
                                                                    <GrCloudComputer style={{ fontSize: "30px", marginTop: "10px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <label for="inputEmail3" class="col-sm-3 col-form-label text-end">Blog Content</label>
                                                            <div class="col-sm-9 custom-edit-1">
                                                                <ReactQuill
                                                                    className="custom-quill"
                                                                    ref={quillRef}
                                                                    style={{ width: '100%' }}
                                                                    value={formik.values.blogcontent}
                                                                    onChange={handleChange}
                                                                    modules={modules}
                                                                    placeholder="Write something..."
                                                                />
                                                                {formik.touched.blogcontent && formik.errors.blogcontent ? (<span style={{ fontSize: "12px" }}>{formik.errors.blogcontent}</span>) : null}
                                                                <button className={`${formik.isSubmitting ? "disable-btn-1 mt-4" : "liquidity-btn-1 mt-4"}`} type="submit" disabled={formik.isSubmitting}>Update</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Editblog