import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import DataTable from 'react-data-table-component'
import { CiCirclePlus } from "react-icons/ci";
import render from "../assets/images/favicon.png";
import config from "../config/config";
import Web3 from "web3";
import metamask from "../assets/images/metamask.png";
import multiRouterAbi from "../Abi/multiRouter.json";
import { ToastContainer, toast } from "react-toastify";
import swapRouterAbi from "../Abi/routerContract.json";
import {
    useAccount,
    useConnect,
    useDisconnect,
    useEnsAvatar,
    useEnsName,
    useNetwork,
    useSwitchNetwork,
} from "wagmi";


import ethereum from "../assets/images/ethereum.png";
import polygon from "../assets/images/polygon.png";
import bnb from "../assets/images/bnb.png";
import dcx from "../assets/images/dchainimages.png";


function Fees(props) {
    const { data } = props;
    const { connect, connectors, isLoading, pendingConnector } = useConnect();
    const { chain } = useNetwork();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const { chains, pendingChainId, error, switchNetwork } = useSwitchNetwork();
    const { address, isConnected } = useAccount();
    const { data: ensName } = useEnsName({ address });
    const { data: ensAvatar } = useEnsAvatar({ name: ensName });
    const trigerWalletRef = useRef(null);
    const { disconnect } = useDisconnect();
    const [status, setStatus] = useState(true);

    const [Multi_netWorkId, setMultiNetworkId] = useState();
    const [swap_netWorkId, setSwapNetworkId] = useState();

    const [Multi_Rpc, setMulti_Rpc] = useState('');
    const [swap_Rpc, setSwap_Rpc] = useState('');

    const [MultiContract, setMultiContract] = useState(null);
    const [SwapContract, setSwapContract] = useState(null);

    const [selectedOptionswap, setSelectedOption] = useState(null);
    const [MultiNetworkOption, setMultiNetworkOption] = useState(null);

    const [swapFee, setSwapFee] = useState(0);
    const [multiFee, setMultiFee] = useState(0);

    // const [ownerAddressSwap, setOwnerAddressSwap] = useState(null);
    // const [ownerAddressMulti, setOwnerAddressMulti] = useState(null);


    const handleConnect = async () => {
        if (window.ethereum && window.ethereum.isMetaMask) {
            try {

                // const web3 = new Web3(window.ethereum);
                // const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                // const account = accounts[0];
                // if (!account) {
                //   throw new Error('No MetaMask account found');
                // }
                // const message = "Please sign this message to verify your identity.";
                // const signature = await web3.eth.personal.sign(message, account, '');
                connect({ connector: connectors[0] });
                // console.log('Signature:', signature);
            } catch (error) {
                console.error('Error connecting to MetaMask:', error);
            }
        } else {
            window.open('https://metamask.io/download.html', '_blank');
        }
    };

    const options = [
        { value: config.Rpc.Eth.chainId, label: "ETH" },
        { value: config.Rpc.poly.chainId, label: "POLYGON" },
        { value: config.Rpc.Bnb.chainId, label: "BNB" },
    ];

    const options1 = [
        { value: config.Rpc.Eth.chainId, label: "ETH" },
        { value: config.Rpc.poly.chainId, label: "POLYGON" },
        { value: config.Rpc.Bnb.chainId, label: "BNB" },
        { value: config.Rpc.Dchain.chainId, label: "Dcx" }
    ];

    const handleChangeMulti = (chainId) => {
        try {
            const selectedOption = options.find(option => option.value === chainId);
            const selectedOption1 = options1.find(option => option.value === chainId);
            console.log('selectedOption', selectedOption)
            if (selectedOption) {
                // Switch network using wagmi
                switchNetwork?.(chainId);
                setMultiNetworkOption(selectedOption);
                setMultiNetworkId(selectedOption.value);

                setMulti_Rpc(
                    selectedOption.value === config.Rpc.Eth.chainId ? config.Rpc.Eth.rpc :
                        selectedOption.value === config.Rpc.poly.chainId ? config.Rpc.poly.rpc :
                            selectedOption.value === config.Rpc.Bnb.chainId ? config.Rpc.Bnb.rpc : null
                );

                setMultiContract(
                    selectedOption.value === config.Rpc.Eth.chainId ? config.M_Router.Eth.eth_multiRouter :
                        selectedOption.value === config.Rpc.poly.chainId ? config.M_Router.poly.poly_multiRouter :
                            selectedOption.value === config.Rpc.Bnb.chainId ? config.M_Router.Bnb.bsc_multiRouter : null
                );
            } else {
                console.log('No matching option found for chainId:', chainId);
            }

            if (selectedOption1) {
                switchNetwork?.(chainId);
                setSelectedOption(selectedOption1);
                setSwapNetworkId(selectedOption1.value);
                setSwap_Rpc(
                    selectedOption1.value == config.Rpc.Eth.chainId ? config.Rpc.Eth.rpc :
                        selectedOption1.value == config.Rpc.poly.chainId ? config.Rpc.poly.rpc :
                            selectedOption1.value == config.Rpc.Bnb.chainId ? config.Rpc.Bnb.rpc :
                                selectedOption1.value == config.Rpc.Dchain.chainId ? config.Rpc.Dchain.rpc :
                                    null
                )
                setSwapContract(
                    selectedOption1.value == config.Rpc.Eth.chainId ? config.D_Router.Eth.D_Ethereum_Router :
                        selectedOption1.value == config.Rpc.poly.chainId ? config.D_Router.poly.D_Polygon_Router :
                            selectedOption1.value == config.Rpc.Bnb.chainId ? config.D_Router.Bnb.D_BNB_Router :
                                selectedOption1.value == config.Rpc.Dchain.chainId ? config.D_Router.Dchain.dcx_Router_Contract : null
                )
            } else {
                console.log('No matching option found for chainId:', chainId);
            }
        } catch (error) {
            console.log('error', error)
        }
    };
    const handleChangeSwap = (option) => {
        setSelectedOption(option);
        setSwapNetworkId(option.value);
        setSwap_Rpc(
            option.value == config.Rpc.Eth.chainId ? config.Rpc.Eth.rpc :
                option.value == config.Rpc.poly.chainId ? config.Rpc.poly.rpc :
                    option.value == config.Rpc.Bnb.chainId ? config.Rpc.Bnb.rpc :
                        option.value == config.Rpc.Dchain.chainId ? config.Rpc.Dchain.rpc :
                            null
        )
        setSwapContract(
            option.value == config.Rpc.Eth.chainId ? config.D_Router.Eth.D_Ethereum_Router :
                option.value == config.Rpc.poly.chainId ? config.D_Router.poly.D_Polygon_Router :
                    option.value == config.Rpc.Bnb.chainId ? config.D_Router.Bnb.D_BNB_Router :
                        option.value == config.Rpc.Dchain.chainId ? config.D_Router.Dchain.dcx_Router_Contract : null
        )
    };

    const getFeeMuti = async () => {
        try {
            const provider = new Web3.providers.HttpProvider(Multi_Rpc);
            const web3 = new Web3(provider);            const contract = new web3.eth.Contract(multiRouterAbi, MultiContract);
            const Fee = await contract.methods.Fee().call();
            setMultiFee(Number(Fee) / 10 ** 18);
            // const owner = await contract.methods.owneraddress().call();
            // setOwnerAddressMulti(owner)
        } catch (error) {
            setMultiFee(0);
        }
    }

    const getFeeswap = async () => {
        try {
            const provider = new Web3.providers.HttpProvider(swap_Rpc);
            const web3 = new Web3(provider);
            const contract = new web3.eth.Contract(swapRouterAbi, SwapContract);
            const Fee = await contract.methods.Fee().call();
            setSwapFee(Number(Fee) / 10 ** 18);
            // const owner = await contract.methods.owneraddress().call();
            // setOwnerAddressSwap(owner);
        } catch (error) {
            setSwapFee(0);
        }
    }

    useEffect(() => {
        if (MultiContract !== null) {
            getFeeMuti();
        }
    }, [MultiContract])

    useEffect(() => {
        if (SwapContract !== null) {
            getFeeswap();
        }
    }, [SwapContract])



    const handlechangeSwapFee = (e) => {
        try {
            const value = e;
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value) && !/^0{2,}/.test(value)) {
                setSwapFee(value);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handlechangeMultiFee = (e) => {
        try {
            const value = e;
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (regex.test(value) && !/^0{2,}/.test(value)) {
                setMultiFee(value);
            }
        } catch (error) {
            console.log(error);
        }
    }



    const changeFeeINSwap = async () => {
        try {
            if (Number(swapFee) === 0) {
                toast("Enter the fee");
                return
            }
            let gas;
            let gesfee;
            let provi1der = window.ethereum ? window.ethereum : new Web3.providers.HttpProvider(swap_Rpc);
            let web3 = new Web3(provi1der);
            // eslint-disable-next-line default-case
            switch (chain?.id) {
                case config.Rpc.Eth.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.Bnb.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.poly.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.Dchain.chainId:
                    gas = 2000000;
                    gesfee = web3.utils.toWei("4", "Gwei");
                    break;
            }

            const contract = new web3.eth.Contract(swapRouterAbi, SwapContract);
            console.log(contract.methods)
            const owneraddress =await contract.methods.owneraddress().call();
            console.log('owneraddress', owneraddress)
            if (owneraddress !== address) {
                return  toast.warn(`Please connect with address ${owneraddress.substring(0, 5)}...${owneraddress.substring(owneraddress.length - 4)}`)
            }

            const wei = Number(swapFee) * 10 ** 18;
            await contract.methods.changeFee(wei).send({ from: address, gas: gas, gasPrice: gesfee });
            toast("Fee change  success");
        } catch (error) {
            console.log(error);
            toast("Fee change  failed");
        }
    }

    const changeFeeINMulti = async () => {
        try {

            if (Number(multiFee) === 0) {
                toast("Enter the fee");
                return
            }
            let gas;
            let gesfee;
            let provi1der = window.ethereum ? window.ethereum : new Web3.providers.HttpProvider(swap_Rpc);
            let web3 = new Web3(provi1der);
            // eslint-disable-next-line default-case
            switch (chain?.id) {
                case config.Rpc.Eth.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.Bnb.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.poly.chainId:
                    gas = 2000000;
                    gesfee = await web3.eth.getGasPrice();
                    break;
                case config.Rpc.Dchain.chainId:
                    gas = 2000000;
                    gesfee = web3.utils.toWei("4", "Gwei");
                    break;
            }

            const contract = new web3.eth.Contract(multiRouterAbi, MultiContract);
            const owneraddress =await contract.methods.owneraddress().call();
            console.log('contract',owneraddress);

            if (owneraddress !== address) {
                return  toast.warn(`Please connect with address ${owneraddress.substring(0, 5)}...${owneraddress.substring(owneraddress.length - 4)}`)
            }

            const wei = Number(multiFee) * 10 ** 18;
            await contract.methods.changeFee(wei).send({ from: address, gas: gas, gasPrice: gesfee });
            toast("Fee change  success");
        } catch (error) {
            console.log(error);
            toast("Fee change  failed");
        }
    }


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? '#07182e'
                : state.isFocused
                    ? '#3659db'
                    : provided.backgroundColor,
            color: state.isSelected
                ? 'white'
                : provided.color,
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: '#004080',
            color: 'white',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
    };

    const columnsone = [
        {
            name: 'number',
            selector: row => row.image,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'TVL',
            selector: row => row.tvl,
            sortable: true,
        },
        {
            name: 'POOL',
            selector: row => row.pool,
            sortable: true,
        },
        {
            name: 'TOKEN A',
            selector: row => row.tokena,
            sortable: true,
        },
        {
            name: 'TOKEN B',
            selector: row => row.tokenb,
            sortable: true,
        },
        {
            name: 'BUTTON 1',
            selector: row => row.button1,
            sortable: true,
        },
        {
            name: 'BUTTON 2',
            selector: row => row.button2,
            sortable: true,
        },
        {
            name: 'BUTTON 3',
            selector: row => row.button3,
            sortable: true,
        },
    ];
    const dataone = [
        {
            id: 1,
            image: <div>s.no</div>,
            name: 'DINU/CINU',
            tvl: <div>$7.01</div>,
            pool: <div>0x5d4…7a1ac</div>,
            tokena:
                <div>
                    hhhhhhhhhhhhh
                </div>,
            tokenb:
                <div>
                    1
                </div>,
            button1:
                <div>
                    <button className='liquidity-btn-1'>V2</button>
                </div>,
            button2:
                <div>
                    <button className='liquidity-btn-1'>o.17%</button>
                </div>,
            button3:
                <div>
                    <button className='liquidity-btn-1'><CiCirclePlus /></button>
                </div>,
        },

    ];


    useEffect(() => {

        if (data === true && trigerWalletRef.current && isConnected === false) {
            trigerWalletRef.current.click();
            setIsDropdownOpen(true);
        }
    }, [data, setIsDropdownOpen]);

    return (
        <div className="App">
            <div className="container-fluid">
                <div className="custom-fees min-vh-100 text-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <div className="d-flex justify-content-between align-items-center">
                                    <h2>Fees</h2>
                                    <div className="d-flex">
                                        <ul class="navbar-nav ms-auto-lg mb-2 me-3 mb-lg-0 gap-1 nav-dropdown-second justify-content-center align-items-center">
                                            <li class="nav-item dropdown home-hide">
                                                <div class="dropdown">
                                                    <button
                                                        class="btn gap-2 d-block nav-link dropbtn"
                                                        type="submit"
                                                        href="#"
                                                        role="button"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"
                                                    >
                                                        <img
                                                            src={
                                                                chain?.name === "Ethereum"
                                                                    ? ethereum
                                                                    : chain?.name === "BNB Smart Chain"
                                                                        ? bnb
                                                                        : chain?.name === "Polygon"
                                                                            ? polygon
                                                                            : dcx
                                                            }
                                                            className="ethe-img"
                                                            alt="etherum"
                                                        />
                                                        {chain?.name == "Ethereum" ||
                                                            chain?.name == "BNB Smart Chain" ||
                                                            chain?.name == "Polygon" ? (
                                                            <span className="mobile-none"> {chain?.name} </span>
                                                        ) : (
                                                            <span className="mobile-none"> D Chain Mainnet </span>
                                                        )}
                                                    </button>
                                                    <ul class="dropdown-menu" style={{ cursor: "pointer" }}>
                                                        {/* <li>
                      <div className="dropdown-tx-2 d-flex">
                        <input
                          class="form-control me-2 ps-2"
                          type="search"
                          placeholder="Search Network"
                          aria-label="Search"
                        />
                      </div>
                    </li> */}
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => handleChangeMulti(1)}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={ethereum}
                                                                        className="ethe-img"
                                                                        alt="etherum"
                                                                    />
                                                                    <span className="dropdown-tx-2 ps-lg-2">
                                                                        {" "}
                                                                        Ethereum
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => handleChangeMulti(137)}
                                                            >
                                                                <div>
                                                                    <img
                                                                        src={polygon}
                                                                        className="ethe-img"
                                                                        alt="polygon"
                                                                    />
                                                                    <span className="dropdown-tx-2 ps-lg-2">
                                                                        {" "}
                                                                        Polygon
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => handleChangeMulti(56)}
                                                            >
                                                                <div>
                                                                    <img src={bnb} className="ethe-img" alt="bnb" />
                                                                    <span className="dropdown-tx-2 ps-lg-2"> BNB</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                class="dropdown-item"
                                                                // href="#"
                                                                onClick={() => handleChangeMulti(5272)}
                                                            >
                                                                <div>
                                                                    <img src={dcx} className="ethe-img" alt="dcx" />
                                                                    <span className="dropdown-tx-2 ps-lg-2"> DCX</span>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>

                                        <div className="d-flex">
                                            {isConnected ? (
                                                <button
                                                    class="btn nav-link dropbtn me-3"
                                                    type="submit"
                                                    href="#"
                                                    role="button"
                                                    aria-expanded="false"
                                                >
                                                    {address.substring(0, 5)}...
                                                    {address.substring(address.length - 4)}
                                                </button>
                                            ) : (
                                                <button
                                                    ref={trigerWalletRef}
                                                    class="btn nav-link liquidity-btn-1 connect-wallet"
                                                    type="submit"
                                                    href="#"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Connect Wallet
                                                </button>
                                            )}
                                            <ul
                                                className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                                                class="dropdown-menu"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item1"
                                                        // href="#"
                                                        onClick={() => {
                                                            handleConnect()
                                                        }}
                                                    >
                                                        <div className="d-flex align-items-center" style={{ cursor: "pointer" }}>
                                                            <img
                                                                src={metamask}
                                                                className="ethe-img"
                                                                alt="metamask"
                                                            />
                                                            <span className="dropdown-tx-2 ps-lg-2">
                                                                MetaMask
                                                            </span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                            {isConnected === true ? (
                                                <>
                                                    <button
                                                        class="liquidity-btn-2 me-2"
                                                        type="submit"
                                                        href="#"
                                                        role="button"
                                                        aria-expanded="false"
                                                        onClick={disconnect}
                                                    >
                                                        Disconnect
                                                    </button>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="custom-inside-fees">
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link active fees-button"
                                                        id="pills-home-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#pills-home"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="pills-home"
                                                        onClick={() => {
                                                            setStatus(true)
                                                        }}
                                                        aria-selected="true"
                                                    >
                                                        Swap aggregator
                                                    </button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button
                                                        className="nav-link fees-button"
                                                        id="pills-profile-tab"
                                                        data-bs-toggle="pill"
                                                        data-bs-target="#pills-profile"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="pills-profile"
                                                        aria-selected="false"
                                                        onClick={() => {
                                                            setStatus(false)
                                                        }}
                                                    >
                                                        Swap Router
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="pills-home"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-home-tab"
                                                    tabIndex="0"
                                                >
                                                    <div className="custom-fees-modal">
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <div className="custom-fees-input">
                                                                    <form>
                                                                        <div className="mb-3 g-2 row">
                                                                            <label
                                                                                htmlFor="inputPassword"
                                                                                className="col-sm-3 col-form-label"
                                                                            >
                                                                                Chain :
                                                                            </label>
                                                                            <div className="col-sm-9 ">
                                                                                <Select
                                                                                    value={MultiNetworkOption}
                                                                                    onChange={(option) => handleChangeMulti(option.value)}
                                                                                    options={options}
                                                                                    placeholder="Select a chain"
                                                                                    isSearchable
                                                                                    styles={customStyles}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-3 col-form-label">Fees :</label>
                                                                            <div class="col-sm-9">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" placeholder="gggg" value={multiFee} onChange={(e) => { handlechangeMultiFee(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <div className="text-center">
                                                                        {chain?.id ? chain?.id == Multi_netWorkId ? <button className="liquidity-btn-1" onClick={() => {
                                                                            changeFeeINMulti()
                                                                        }}>submit</button> :
                                                                            <button className="liquidity-btn-1 opacity-50"
                                                                            >submit</button> : <button className="liquidity-btn-1 opacity-50"
                                                                            >submit</button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className=" mt-3">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="liquidity-table-1">
                                                                        <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                                            pagination
                                                                            highlightOnHover
                                                                            dense />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="pills-profile"
                                                    role="tabpanel"
                                                    aria-labelledby="pills-profile-tab"
                                                    tabIndex="0"
                                                >
                                                    <div className="custom-fees-modal">
                                                        <div className="row">
                                                            <div className="col-lg-7">
                                                                <div className="custom-fees-input">
                                                                    <form>
                                                                        <div className="mb-3 g-2 row">
                                                                            <label
                                                                                htmlFor="inputPassword"
                                                                                className="col-sm-3 col-form-label"
                                                                            >
                                                                                Chain :
                                                                            </label>
                                                                            <div className="col-sm-9 ">
                                                                                <Select
                                                                                    value={selectedOptionswap}
                                                                                    onChange={(option) => handleChangeMulti(option.value)}
                                                                                    options={options1}
                                                                                    placeholder="Select a chain"
                                                                                    isSearchable
                                                                                    styles={customStyles} // Apply custom styles
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div class="mb-3 row">
                                                                            <label for="inputPassword" class="col-sm-3 col-form-label">Fee :</label>
                                                                            <div class="col-sm-9">
                                                                                <input type="text" class="form-control input-text-1" id="inputPassword" placeholder="gggg" value={swapFee} onChange={(e) => { handlechangeSwapFee(e.target.value) }} />
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                    <div className="text-center">
                                                                        {chain?.id ? chain?.id == swap_netWorkId ? <button className="liquidity-btn-1" onClick={() => {
                                                                            changeFeeINSwap()
                                                                        }}>submit</button> : <button className="liquidity-btn-1 opacity-50" >submit</button> : <button className="liquidity-btn-1 opacity-50" >submit</button>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <div className=" mt-3">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="liquidity-table-1">
                                                                        <DataTable columns={columnsone} data={dataone} theme="solarized" defaultSortAsc={true}
                                                                            pagination
                                                                            highlightOnHover
                                                                            dense />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Fees;
